import React from 'react'
import Store from './Store'
import Globe from './components/Globe'
import ModalApp from './components/ModalApp'

const App = () => {
  return (
    <Store>
      <ModalApp />
      <Globe />
    </Store>
  )
}

export default App
