import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Context } from '../../Store'
import ReactGlobe from 'react-globe'
import markers from '../../markers.js'
import MediaCard from '../MediaCard'
import Welcome from '../Welcome'

import './Globe.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

// const rust = new THREE.Color('rgb(210, 105, 74)')
// const purple = new THREE.Color('rgb(237, 216, 233)')

// console.log(rust.getHexString())

function markerTooltipRenderer (marker) {
  return marker.songTitle
}

const Globe = () => {
  const [state, dispatch] = useContext(Context)

  if (state.isCookieConsent) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)

    ReactPixel.init(
      process.env.REACT_APP_FB_PIXEL_ID,
      {},
      {
        autoConfig: true,
        debug: false
      }
    )

    ReactPixel.pageView() // For tracking page view
  }

  const [activeTrack, setActiveTrack] = useState()

  const initialCoordinates = useMemo(() => {
    return [34.04750093145216, -118.26718098343436]
  }, [])
  const initialCameraDistanceRadiusScale = useMemo(() => {
    return 6
  }, [])

  const options = useMemo(() => {
    return {
      ambientLightColor: 'indianred',
      ambientLightIntensity: 3,
      pointLightColor: 'red',
      pointLightIntensity: 1,
      pointLightPositionRadiusScales: [2, 1, -1],
      enableGlobeGlow: true,
      globeGlowCoefficient: 0.1,
      globeGlowColor: 'coral',
      globeGlowPower: 18,
      globeGlowRadiusScale: 1.5,
      enableMarkerTooltip: true,
      enableMarkerGlow: true,
      // markerEnterAnimationDuration: 2000,
      // markerEnterEasingFunction: ['Bounce', 'InOut'],
      // markerExitAnimationDuration: 2000,
      // markerExitEasingFunction: ['Cubic', 'Out'],
      markerTooltipRenderer,
      // markerRadiusScaleRange: [0.01, 0.05],
      markerRadiusScaleRange: [0.03, 0.08],

      focusAnimationDuration: 2000,
      focusDistanceRadiusScale: 2,
      focusEasingFunction: ['Quadratic', 'InOut'],
      cameraDistanceRadiusScale: 6,
      // cameraMaxDistanceRadiusScale: 8,
      // cameraZoomSpeed: 1,
      // enableCameraZoom: true,
      cameraAutoRotateSpeed: 0.4,
      // cameraMaxPolarAngle: (Math.PI * 9) / 16,
      // cameraMinPolarAngle: (Math.PI * 7) / 16,
      // cameraRotateSpeed: 0.2,
      // enableCameraAutoRotate: true,
      // enableCameraRotate: true
      markerEnterAnimationDuration: 10000,
      markerEnterEasingFunction: ['Elastic', 'InOut']
    }
  }, [])

  useEffect(() => {
    if (
      state.activeMarker.length !== 0 &&
      state.activeMarker.marker.songPath !== undefined
    ) {
      setActiveTrack(state.activeMarker.marker.songPath)
    }
  }, [state.activeMarker])

  function onClickMarker (marker, markerObject, event) {
    dispatch({
      type: 'MARKER_CLICKED',
      payload: {
        marker,
        pointerEventPosition: { x: event.clientX, y: event.clientY }
      }
    })

    ReactGA.event({
      category: 'clicked_marker',
      action: `User clicked on ${marker.artist}`
    })
  }

  function onDefocus (marker) {
    dispatch({ type: 'MARKER_UNCLICKED', payload: marker })
  }

  return (
    <div className={state.isModalOpen ? 'globe' : 'globe--isActive'}>
      <div>
        <ReactGlobe
          globeTexture={
            'https://assets.stayremixed.berneofficial.com/images/globe-texture.jpg'
          }
          globeCloudsTexture={
            'https://assets.stayremixed.berneofficial.com/images/globe-clouds.png'
          }
          initialCameraDistanceRadiusScale={initialCameraDistanceRadiusScale}
          initialCoordinates={initialCoordinates}
          globeBackgroundTexture={null}
          height='100vh'
          markers={!state.isModalOpen ? markers : []}
          width='100vw'
          options={options}
          onClickMarker={onClickMarker}
          onTouchMarker={onClickMarker}
          onDefocus={onDefocus}
        />
      </div>

      {!state.isModalOpen && <Welcome show={state.activeMarker.length === 0} />}

      {state.activeMarker.length !== 0 && (
        <MediaCard
          artist={state.activeMarker.marker.artist}
          location={state.activeMarker.marker.location}
          songTitle={state.activeMarker.marker.songTitle}
          img={state.activeMarker.marker.img}
          profile={state.activeMarker.marker.profile}
          audioSrc={activeTrack}
          showDetails={state.isMarkerClicked}
          onClickMinimise={onDefocus}
        />
      )}
    </div>
  )
}

export default Globe
