import React from 'react'

const WelcomeBackContainer = props => {
  return (
    <div>
      <h2 className='form--subtitle'>Nice to see you, {props.name}.</h2>

      <button
        // size='medium'
        // variant='outlined'
        // color='secondary'
        className='form-submit-button'
        onClick={props.onClickDismiss}
      >
        Let's go!
      </button>
    </div>
  )
}

export default WelcomeBackContainer
