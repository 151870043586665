import CookieConsent from 'react-cookie-consent'
import React from 'react'
import './CookieConsentOverlay.css'

const CookieConsentOverlay = props => {
  return (
    <CookieConsent
      location='bottom'
      cookieName='berneEarthConsent'
      disableStyles={true}
      containerClasses='cookie-consent--container'
      buttonClasses='cookie-consent--button'
      overlayClasses='cookie-consent--overlay'
      contentClasses='cookie-consent--content'
      expires={999}
      overlay
      onAccept={props.onAccept}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  )
}
export default CookieConsentOverlay
