const Reducer = (state, action) => {
  switch (action.type) {
    case 'MARKER_CLICKED':
      return {
        ...state,
        isMarkerClicked: true,
        activeMarker: action.payload
      }
    case 'MARKER_UNCLICKED':
      return {
        ...state,
        isMarkerClicked: false
      }
    case 'SUBSCRIPTION_STATUS':
      return {
        ...state,
        subscriptionStatus: action.payload
      }
    case 'IS_MODAL_OPEN':
      return {
        ...state,
        isModalOpen: action.payload
      }
    case 'COOKIE_CONSENT':
      return {
        ...state,
        isCookieConsent: action.payload
      }
    default:
      return state
  }
}

export default Reducer
