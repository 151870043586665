import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../Store'
import Share from '../Share'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined'
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined'
import Carousel from 'react-material-ui-carousel'
import ReactGA from 'react-ga'
import AutoPlay from '../AutoPlay'

import './MediaCard.css'

const MediaCard = props => {
  const [state, dispatch] = useContext(Context)
  const [isMinimised, setIsMinimised] = useState(false)

  const onClickMinimiseMaximise = () => {
    setIsMinimised(!isMinimised)
  }

  useEffect(() => {
    if (props.showDetails) {
      setIsMinimised(false)
    }
  }, [props.showDetails, state.activeMarker])

  function onClickDownload () {
    ReactGA.event({
      category: 'clicked_download',
      action: `User clicked download from ${props.artist}`
    })
  }

  const onClickCharityLink = () => {
    ReactGA.event({
      category: 'clicked_charity_link',
      action: `User clicked charity link from ${props.artist}`
    })
  }

  return (
    <div
      className={isMinimised ? 'mediaCard mediaCard--isMinimised' : 'mediaCard'}
    >
      <div className='mediaCard-card'>
        {!isMinimised && (
          <ExpandMoreOutlinedIcon
            className='mediaCard-minimise'
            fontSize='large'
            onClick={onClickMinimiseMaximise}
          />
        )}

        {isMinimised && (
          <ExpandLessOutlinedIcon
            className='mediaCard-minimise'
            fontSize='large'
            onClick={onClickMinimiseMaximise}
          />
        )}

        <img
          className={
            isMinimised ? 'mediaCard-image --isMinimised' : 'mediaCard-image'
          }
          alt={props.songTitle}
          src={props.img}
          title={props.songTitle}
        />

        <div>
          <div
            className='mediaCard-content'
            // onTouchEndCapture={onClickMinimiseMaximise}
          >
            <p className='mediaCard-city'>
              {props.location.city
                ? `${props.location.city}, ${props.location.country}`
                : props.location.country}
            </p>
            <h2 className='mediaCard-songtitle'>{props.songTitle}</h2>

            <div className='mediaCard-cta-wrapper'>
              {props.audioSrc && <AutoPlay src={props.audioSrc} />}
              <div
                className={
                  !isMinimised
                    ? 'mediaCard-cta'
                    : 'mediaCard-cta mediaCard-cta--minmised'
                }
              >
                <div>
                  <Share />
                </div>
                <div>
                  <a
                    className='mediaCard-cta--download'
                    href='https://soundslikeberne.bandcamp.com/album/stay-remixed'
                    target='_blank'
                    onClick={onClickDownload}
                  >
                    <GetAppOutlinedIcon fontSize='small' />
                  </a>
                </div>
              </div>
            </div>
            <div
              className={
                isMinimised ? 'mediaCard-text --isMinimised' : 'mediaCard-text'
              }
            >
              {props.profile.charity ? (
                <Carousel
                  key={props.artist}
                  index={0}
                  className='mediaCard-content--carousel'
                  autoPlay={false}
                  swipe
                  // navButtonsAlwaysVisible
                  indicatorIconButtonProps={{
                    style: {
                      padding: '0px',
                      paddingBottom: '0.8px',
                      color: '#ffeee6'
                    }
                  }}
                  activeIndicatorIconButtonProps={{
                    style: {
                      backgroundColor: '#d2694a'
                    }
                  }}
                  animation={'slide'}
                  fullHeightHover={true} // We want the nav buttons wrapper to only be as big as the button element is
                  navButtonsProps={{
                    // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                    style: {
                      // backgroundColor: 'rgb(210 105 74 / 77%)',
                      backgroundColor: '#d2694a',
                      borderRadius: '10'
                    }
                  }}
                  navButtonsWrapperProps={{
                    // Move the buttons to the bottom. Unsetting top here to override default style.
                    style: {
                      bottom: '50px',
                      top: 'unset'
                    }
                  }}
                >
                  <div className='mediaCard-content--item'>
                    <p>{props.profile.bio}</p>
                  </div>
                  <div className='mediaCard-content--item'>
                    <h5>
                      Environmental highlight in
                      {props.location.city
                        ? ` ${props.location.city}`
                        : ` ${props.location.country}`}
                    </h5>
                    <p>{props.profile.highlight}</p>
                  </div>
                  {props.profile.charity && (
                    <div className='mediaCard-content--item'>
                      <h5>
                        Supported Charity in
                        {props.location.city
                          ? ` ${props.location.city}`
                          : ` ${props.location.country}`}
                      </h5>
                      <p>{props.profile.charity.text}</p>
                      <a
                        className='mediaCard-content-link'
                        href={props.profile.charity.link}
                        target='_blank'
                        onClick={onClickCharityLink}
                      >
                        Find out more
                      </a>
                    </div>
                  )}
                </Carousel>
              ) : (
                <div>
                  <Carousel
                    key={props.artist}
                    index={0}
                    className='mediaCard-content--carousel'
                    autoPlay={false}
                    swipe
                    // navButtonsAlwaysVisible
                    indicatorIconButtonProps={{
                      style: {
                        padding: '0px',
                        paddingBottom: '0.8px',
                        color: '#ffeee6'
                      }
                    }}
                    activeIndicatorIconButtonProps={{
                      style: {
                        backgroundColor: '#d2694a'
                      }
                    }}
                    animation={'slide'}
                    fullHeightHover={true} // We want the nav buttons wrapper to only be as big as the button element is
                    navButtonsProps={{
                      // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                      style: {
                        // backgroundColor: 'rgb(210 105 74 / 77%)',
                        backgroundColor: '#d2694a',
                        borderRadius: '10'
                      }
                    }}
                    navButtonsWrapperProps={{
                      // Move the buttons to the bottom. Unsetting top here to override default style.
                      style: {
                        bottom: '50px',
                        top: 'unset'
                      }
                    }}
                  >
                    <div className='mediaCard-content--item'>
                      <h5>Thanks for being here.</h5>
                      <p>{props.profile.bio}</p>
                    </div>
                    <div className='mediaCard-content--item'>
                      <h5>About us</h5>
                      <p>{props.profile.highlight}</p>
                    </div>
                  </Carousel>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaCard
