import React, { useEffect, useContext, useState } from 'react'
import { Context } from '../../Store'
import 'wicg-inert'
import { CSSTransition } from 'react-transition-group'

import Portal from '../Portal'
import './Modal.css'

export default function Modal (props) {
  const [state, dispatch] = useContext(Context)

  const [active, setActive] = useState(false)
  const { open, onClose, locked } = props
  const backdrop = React.useRef(null)

  useEffect(() => {
    const { current } = backdrop

    const transitionEnd = () => setActive(open)

    const keyHandler = e => !locked && [27].indexOf(e.which) >= 0 && onClose()

    const clickHandler = e => !locked && e.target === current && onClose()

    if (current) {
      current.addEventListener('transitionend', transitionEnd)
      current.addEventListener('click', clickHandler)
      window.addEventListener('keyup', keyHandler)
    }

    if (open) {
      dispatch({
        type: 'IS_MODAL_OPEN',
        payload: true
      })
      window.setTimeout(() => {
        document.activeElement.blur()
        setActive(open)
        document.querySelector('#root').setAttribute('inert', 'true')
        // document.querySelector('.globe').classList.add('globe--disabled')
      }, 10)
    }

    if (!open && !locked) {
      dispatch({
        type: 'IS_MODAL_OPEN',
        payload: false
      })
    }

    return () => {
      if (current) {
        current.removeEventListener('transitionend', transitionEnd)
        current.removeEventListener('click', clickHandler)
      }

      document.querySelector('#root').removeAttribute('inert')
      window.removeEventListener('keyup', keyHandler)
    }
  }, [open, locked, onClose])

  return (
    <CSSTransition
      in={open || active}
      timeout={4000}
      classNames='fade'
      unmountOnExit
      appear
    >
      <React.Fragment>
        {(open || active) && (
          <Portal className='modal-portal'>
            <div
              ref={backdrop}
              className={
                active && open ? 'modal-backdrop active' : 'modal-backdrop'
              }
            >
              <div className='modal-content-wrapper'>
                <div className='modal-content'>{props.children}</div>
              </div>
            </div>
          </Portal>
        )}
      </React.Fragment>
    </CSSTransition>
  )
}
