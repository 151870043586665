import React, { useContext } from 'react'
import { Context } from '../../Store'

import MailchimpSubscribe from 'react-mailchimp-subscribe'
import CustomForm from '../CustomForm'
import './MailchimpFormContainer.css'

const url = `https://berneofficial.us12.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`

const MailchimpFormContainer = () => {
  const [state, dispatch] = useContext(Context)

  return state.isCookieConsent ? (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <div>
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        </div>
      )}
    />
  ) : null
}

export default MailchimpFormContainer
