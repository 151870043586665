const markers = [
  {
    id: 1,
    location: {
      city: 'Berlin',
      country: 'Germany'
    },
    artist: 'WOJTEK',
    songTitle: 'Heat (WOJTEK Remix)',
    songPath: 'https://assets.stayremixed.berneofficial.com/audio/heat.mp3',
    color: '#edd8e9',
    coordinates: [52.693568069750675, 13.429870656408557],
    value: 30,
    img: 'https://assets.stayremixed.berneofficial.com/images/wojtek.png',
    profile: {
      bio:
        'WOJTEK is a self-taught virtuoso deeply engaged in the climate movement. His new album ‘Atmosphere’ was recorded and produced carbon neutrally. As a fellow planeteer, WOJTEK captured our hearts and minds. A collaboration that stemmed from our mutual passion for the planet.',
      highlight:
        'The people of Berlin are all very aware of the climate crisis. The city offers a highly developed car sharing system, plenty of open-air markets with fresh local food, second-hand shops, food-saving systems like ‘Too Good To Go’ and the ‘Pfand System’ which is a bottle deposit return scheme.',
      charity: {
        text:
          'There is a strong Music Declares Emergency team. It’s a group of artists, music industry professionals and organisations that stand together to declare a climate and ecological emergency and call for an immediate governmental response to protect all life on Earth.',
        link: 'musicdeclares.net/de/'
      }
    }
  },
  {
    id: 2,
    location: {
      city: 'Los Angeles',
      country: 'USA'
    },
    artist: 'Dozier',
    songTitle: 'To The Lions (Dozier Remix)',
    songPath: 'https://assets.stayremixed.berneofficial.com/audio/ttl.mp3',
    color: '#edd8e9',
    coordinates: [34.04750093145216, -118.26718098343436],
    value: 30,
    img: 'https://assets.stayremixed.berneofficial.com/images/dozier.png',
    profile: {
      bio:
        'Dozier is a transdimensional vagabond, with a knapsack full of stories and soundtracks. We met Dozier when the world went fully online in 2020. Since then, we’ve been on many calls to discuss a number of different projects (watch this space). We are so pleased to have collaborated on ‘To The Lions’ Remix. It’s a track that brought a lot of joy in a very uncertain period of our lives.',
      highlight:
        '32,735 students participated in a Grades of Green’s ‘Climate Solutions Campaign’ in Los Angeles that led to 6,608 planted trees, 775 tonnes of CO2 sequestered per year, removed 51,518 pieces of plastic litter, and diverted 1,410 pounds of waste from landfills. This was all done in 2020-2021 despite all the challenges presented by the pandemic.',
      charity: {
        text:
          'Grades of Green provides students with the support and resources they need to discover their passion and turn it into environmental action. They offer personalized mentorship and hands-­on environmental leadership training through project­‐based learning. To date, Grades of Green has inspired over 670,000 students in 47 states and 27 countries to care for the environment.',
        link: 'https://gradesofgreen.givingfuel.com/general-giving'
      }
    }
  },
  {
    id: 3,
    location: {
      city: 'Malmö',
      country: 'Sweden'
    },
    artist: 'Joanna Åström',
    songTitle: 'Oceans (Joanna Åström Remix)',
    songPath: 'https://assets.stayremixed.berneofficial.com/audio/oceans.mp3',
    color: '#edd8e9',
    coordinates: [59.3293, 18.0686],
    value: 30,
    img: 'https://assets.stayremixed.berneofficial.com/images/joanna.png',
    profile: {
      bio:
        'Joanna is an independent producer, DJ and sound designer originally from Sweden. After living in 6 countries and travelling across fifty-something nations, she finally settled in the vibrant London in 2015, which is where we met in 2019. ‘Oceans’ was the first remix collaboration of the Remix EP and we enjoyed every single part of the process from the first draft to the final track.',
      highlight:
        'The comprehensive recycling system in Sweden works really well. In Joanna’s hometown of Malmö, there are 8 dispensers for different kinds of waste such as compost, hard plastic, soft plastic, clear glass, metal etc. as well as a return scheme with financial incentives for recycling bottles and cans with deposit stations at every grocery store.',
      charity: {
        text:
          '‘Pantamera’ (Recycle More) is the leading organisation that manages the ‘Pant-System’ for recycling cans and bottles. In addition to managing the recycling of cans and bottles, they create awareness through advertisements and through collaborating with artists to write songs about recycling, which has become a very effective way to spread the message to the masses. They also run various charity campaigns and are a longtime partner of the main recycling charity in Sweden called ‘Håll Sverige Rent’ (Keep Sweden Clean).',
        link: 'https://hsr.se/'
      }
    }
  },
  {
    id: 4,
    location: {
      country: 'Malta'
    },
    artist: 'KNTRL',
    songTitle: 'Stay (KNTRL Remix)',
    songPath: 'https://assets.stayremixed.berneofficial.com/audio/stay.mp3',
    color: '#edd8e9',
    coordinates: [36.114826023762475, 14.345367516167155],
    value: 30,
    img: 'https://assets.stayremixed.berneofficial.com/images/kntrl.png',
    profile: {
      bio:
        'KNTRL merges influences of alternative music and the dark side of electronic music. He is known for founding the pioneering alternative/electronic Maltese band Dolls for Idols, which is how we met KNTRL back in the late 2000s. We even played the same festival stage one summer night in 2011. Ten years later, we collaborated on ‘Stay’ Remix, which was played on BBC Introducing last summer.',
      highlight:
        'Not only is climate change posing significant environmental issues, but it is increasing social inequality within and between countries. We believe that rich and industrialised countries should be most responsible for the climate crisis, so these must act first and foremost to mitigate the impacts of climate change. Malta is no exception to this, and Friends of the Earth Malta is seeking to influence the government to make positive changes in policies that will be in favour of both the people and the planet.',
      charity: {
        text:
          'Moviment Graffitti is active against the oppression and exploitation of people, environment and animals; with a vision of freedom and radical democracy. It is autonomous from any economic power or political party and practices radical democracy within its structures.',
        link: 'https://movimentgraffitti.org/en/donate.html'
      }
    }
  },
  {
    id: 5,
    location: {
      city: 'London',
      country: 'UK'
    },
    artist: 'Berne',
    songTitle: 'Stay Remixed',
    // songPath: './assets/audio/stay.mp3',
    color: 'darkred',
    coordinates: [51.5468438011484, -0.12359618291226004],
    value: 30,
    img: 'https://assets.stayremixed.berneofficial.com/images/berne.png',
    profile: {
      bio:
        'Be sure to check out all the tracks on ‘Stay Remixed‘ by going through all 4 white markers. You can also buy this EP for a pay-what-you-want value by hitting the download button on any artist card. Thanks for supporting us, the remix producers and the charities mentioned in each artist card.',
      highlight:
        'Ethereal Earth-pop duo Berne come with a deep-rooted mission to inspire better care for the planet through their music and lifestyle. Their dreamy electro-pop songs poetically bring to the surface current issues of the climate emergency, diversity and the refugee crisis, and animal rights, while instilling hope for the future. With Stay Remixed, Berne continue in their mission to inspire and urge people to bring about change in the world, to highlight that we are in charge of the future and that together we can make a real difference.'
    }
  }
]

export default markers
