import React, { useEffect, useContext, useState } from 'react'
import { Context } from '../../Store'
import { useCookies } from 'react-cookie'
import Loading from '../Loading'

import './CustomForm.css'

const CustomForm = props => {
  const [state, dispatch] = useContext(Context)
  const { status, message, onValidated } = props
  const [isBypass, setBypass] = useState(false)
  const [firstName, setFirstName] = useState(null)
  const [isCookie, setCookie] = useCookies([
    'berneEarthName',
    'berneEarthSubscriptionStatus'
  ])

  const MESSAGE_1 = 'has too many recent signup requests'
  const MESSAGE_2 = 'is already subscribed to list'

  useEffect(() => {
    if (status !== null && message !== null && typeof message === 'string') {
      if (message.indexOf(MESSAGE_1) > -1 || message.indexOf(MESSAGE_2) > -1) {
        setBypass(true)
      }
      setCookie('berneEarthName', firstName, { path: '/' })
    }
  }, [status, message])

  useEffect(() => {
    if (status !== null && message !== null) {
      setCookie('berneEarthSubscriptionStatus', isBypass ? 'success' : status, {
        path: '/'
      })
    }

    dispatch({
      type: 'SUBSCRIPTION_STATUS',
      payload: { status, message, bypass: isBypass }
    })
  }, [status, isBypass])

  let email, name

  const submit = e => {
    e.preventDefault()
    name &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
        FNAME: name.value
      })
  }

  const classes = status === 'error' ? 'form form--error' : 'form'
  const Form = (
    <form className='form' onSubmit={submit}>
      <Loading isLoaded={status !== 'sending'} colour='white' />

      {status === 'sending' && (
        <div className='form-message--sending'>sending...</div>
      )}
      {/* {status === 'error' &&
        message !== null &&
        message.indexOf('Timeout') > -1 &&
        !isBypass && (
          <div className='form-message--error'>
            Oops! Your browser might be blocking this site :( Please check for
            any adblockers/tracking protection.
          </div>
        )} */}
      {status === 'error' && message !== 'Error: Timeout' && !isBypass && (
        <div className='form-message--error'>
          Oops! Something's not quite right :( <br />
          <br /> Please check for any adblockers or tracking protection blocking
          this site and try again.
        </div>
      )}
      {/* {status === 'success' && (
        <div
          className='form-message--success'
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )} */}

      <input
        variant='outlined'
        className={`form-input-field ${
          status === 'error' ? 'form--error' : ''
        }`}
        ref={node => (name = node)}
        type='text'
        required
        placeholder='Your name'
        onChange={e => setFirstName(e.target.value)}
      />

      <input
        variant='outlined'
        className={`form-input-field ${
          status === 'error' ? 'form--error' : ''
        }`}
        ref={node => (email = node)}
        type='email'
        required
        placeholder='Your email'
      />
      <button
        type='submit'
        // size='medium'
        // variant='outlined'
        // color='secondary'
        className={`form-submit-button ${
          status === 'error' ? 'form--error' : ''
        }`}
      >
        Submit
      </button>
    </form>
  )

  // return isBypass || status === 'success' ? BypassedForm : Form
  return Form
}

export default CustomForm
