import React from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { css } from '@emotion/react'

// Can be a string as well. Need to ensure each key-value pair ends with ;
// const override = css`
//   display: block;
//   margin: 0 auto;
//   width: 2;
//   border-color: white;
// `

const Loading = props => {
  return (
    <div className='sweet-loading'>
      <ScaleLoader
        color={props.colour ? props.colour : '#d2684a'}
        loading={!props.isLoaded}
        width={2}
        radius={12}
        height={20}
        // css={override}
      />
    </div>
  )
}

export default Loading
