import React from 'react'
import { ReactComponent as Play } from '../../assets/play.svg'
import { ReactComponent as Pause } from '../../assets/pause.svg'

const AudioControls = ({ isPlaying, onPlayPauseClick }) => (
  <div className='audio-controls'>
    {isPlaying ? (
      <button
        type='button'
        className='pause'
        onClick={() => onPlayPauseClick()}
        aria-label='Pause'
      >
        <Pause />
      </button>
    ) : (
      <button
        type='button'
        className='play'
        onClick={() => onPlayPauseClick()}
        aria-label='Play'
      >
        <Play />
      </button>
    )}
  </div>
)

export default AudioControls
