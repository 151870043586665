import React, { useState, useEffect, useRef } from 'react'
import ReactHowler from 'react-howler'

import AudioControls from '../AudioControls'
import raf from 'raf'
import Loading from '../Loading'

import './AutoPlay.css'

const AutoPlay = props => {
  const [isPlaying, setIsPlaying] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)
  const [seek, setSeek] = useState(0.0)
  const [isSeeking, setIsSeeking] = useState(false)
  const [duration, setDuration] = useState()

  let _raf
  const playerRef = useRef()

  const classes = props.showDetails
    ? 'audio-player'
    : 'audio-player audio-player--isMinimised'

  const currentPercentage = duration ? `${(seek / duration) * 100}%` : '0%'
  const trackStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #d2694a), color-stop(${currentPercentage}, #ffeee6))
`
  useEffect(() => {
    // on component will mount
    return () => {
      // on component unmount
      playerRef.current.stop()
      setIsPlaying(false)
      clearRAF()
    }
  }, [])

  useEffect(() => {
    setIsLoaded(false)

    setIsPlaying(true)
  }, [props.src])

  const handleToggle = () => {
    setIsPlaying(!isPlaying)
  }

  const handleOnLoad = () => {
    setIsLoaded(true)
    setDuration(playerRef.current.duration())
  }

  const handleOnLoadError = () => {
    setIsLoaded(false)
  }

  const handleOnPlay = () => {
    setIsPlaying(true)
    renderSeekPos()
  }

  const handleOnEnd = () => {
    setIsPlaying(false)
    clearRAF()
  }

  const handleMouseDownSeek = () => {
    setIsSeeking(true)
  }

  const handleMouseUpSeek = e => {
    setIsSeeking(false)
    playerRef.current.seek(e.target.value)
  }

  const handleSeekingChange = e => {
    setSeek(parseFloat(e.target.value))
  }

  const renderSeekPos = () => {
    if (!isSeeking) {
      setSeek(playerRef.current.seek())
    }
    if (isPlaying) {
      _raf = raf(renderSeekPos)
    }
  }

  const clearRAF = () => {
    raf.cancel(_raf)
  }

  return (
    <div className={classes}>
      {isLoaded && (
        <AudioControls isPlaying={isPlaying} onPlayPauseClick={handleToggle} />
      )}

      {isLoaded ? (
        <div className='audio-scroll'>
          <label>
            <span className='slider-container'>
              <input
                className='progress'
                type='range'
                min='0'
                max={duration ? duration.toFixed(2) : 0}
                step='1'
                value={seek}
                onChange={handleSeekingChange}
                onMouseDown={handleMouseDownSeek}
                onTouchStart={handleMouseDownSeek}
                onKeyUp={handleMouseUpSeek}
                onMouseUp={handleMouseUpSeek}
                onTouchEnd={handleMouseUpSeek}
                style={{ background: trackStyling }}
              />
            </span>
          </label>
        </div>
      ) : (
        <Loading isLoaded={isLoaded} />
      )}

      <ReactHowler
        src={props.src}
        preload
        loop
        html5
        playing={isPlaying}
        onLoadError={handleOnLoadError}
        onLoad={handleOnLoad}
        onPlay={handleOnPlay}
        onEnd={handleOnEnd}
        ref={playerRef}
      />
    </div>
  )
}

export default AutoPlay

// class AutoPlay extends React.Component {
//   constructor (props) {
//     super(props)

//     this.state = {
//       initialized: true,
//       playing: true,
//       loaded: false,
//       mute: false,
//       seek: 0.0,
//       isSeeking: false
//     }
//     this.handleToggle = this.handleToggle.bind(this)
//     this.handleOnLoad = this.handleOnLoad.bind(this)
//     this.handleOnEnd = this.handleOnEnd.bind(this)
//     this.handleOnPlay = this.handleOnPlay.bind(this)
//     this.renderSeekPos = this.renderSeekPos.bind(this)
//     this.handleMouseDownSeek = this.handleMouseDownSeek.bind(this)
//     this.handleMouseUpSeek = this.handleMouseUpSeek.bind(this)
//     this.handleSeekingChange = this.handleSeekingChange.bind(this)
//   }

//   componentWillUnmount () {
//     this.clearRAF()
//   }

//   handleToggle () {
//     this.setState({
//       playing: !this.state.playing
//     })
//   }

//   handleOnLoad () {
//     this.setState({
//       loaded: true,
//       duration: this.player.duration()
//     })
//   }

//   handleOnPlay () {
//     this.setState({
//       playing: true
//     })
//     this.renderSeekPos()
//   }

//   handleOnEnd () {
//     this.setState({
//       playing: false
//     })
//     this.clearRAF()
//   }

//   handleMouseDownSeek () {
//     this.setState({
//       isSeeking: true
//     })
//   }

//   handleMouseUpSeek (e) {
//     this.setState({
//       isSeeking: false
//     })

//     this.player.seek(e.target.value)
//   }

//   handleSeekingChange (e) {
//     this.setState({
//       seek: parseFloat(e.target.value)
//     })
//   }

//   renderSeekPos () {
//     if (!this.state.isSeeking) {
//       this.setState({
//         seek: this.player.seek()
//       })
//     }
//     if (this.state.playing) {
//       this._raf = raf(this.renderSeekPos)
//     }
//   }

//   clearRAF () {
//     raf.cancel(this._raf)
//   }

//   render () {
// const classes = this.props.showDetails
//       ? 'audio-player'
//       : 'audio-player audio-player--isMinimised'

//     const currentPercentage = this.state.duration
//       ? `${(this.state.seek / this.state.duration) * 100}%`
//       : '0%'
//     const trackStyling = `
//     -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #d2694a), color-stop(${currentPercentage}, #ffeee6))
//   `

// return (
//   <div className={classes}>
//     <ReactHowler
//       src={this.props.src}
//       preload
//       loop
//       html5
//       playing={this.state.playing}
//       onLoad={this.handleOnLoad}
//       onPlay={this.handleOnPlay}
//       onEnd={this.handleOnEnd}
//       ref={playerRef}
//       //   ref={ref => (this.player = ref)}
//     />

//     {this.state.loaded && (
//       <AudioControls
//         isPlaying={this.state.playing}
//         onPlayPauseClick={this.handleToggle}
//       />
//     )}

//     {this.state.loaded ? (
//       <div className='audio-scroll'>
//         <label>
//           <span className='slider-container'>
//             <input
//               className='progress'
//               type='range'
//               min='0'
//               max={this.state.duration ? this.state.duration.toFixed(2) : 0}
//               step='1'
//               value={this.state.seek}
//               onChange={this.handleSeekingChange}
//               onMouseDown={this.handleMouseDownSeek}
//               onTouchStart={this.handleMouseDownSeek}
//               onKeyUp={this.handleMouseUpSeek}
//               onMouseUp={this.handleMouseUpSeek}
//               onTouchEnd={this.handleMouseUpSeek}
//               style={{ background: trackStyling }}
//             />
//           </span>
//         </label>
//       </div>
//     ) : (
//       <Loading isLoaded={this.state.loaded} />
//     )}
//   </div>
// )
//   }
// }

// export default AutoPlay
