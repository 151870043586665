import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../Store'

import Modal from '../Modal'
import MailchimpFormContainer from '../MailchimpFormContainer'
import CookieConsentOverlay from '../CookieConsentOverlay'
import WelcomeBackContainer from '../WelcomeBackContainer'
import { withCookies, useCookies } from 'react-cookie'

import './ModalApp.css'

const ModalApp = () => {
  const [state, dispatch] = useContext(Context)

  const [isLocked, setIsLocked] = useState(true)
  const [isLockedOpen, setIsLockedOpen] = useState(true)

  const [cookies] = useCookies([
    'berneEarthName',
    'berneEarthSubscriptionStatus',
    'berneEarthConsent'
  ])

  const unlock = () => {
    setIsLocked(false)
    setIsLockedOpen(false)
  }

  const onCookieConsent = () => {
    dispatch({
      type: 'COOKIE_CONSENT',
      payload: true
    })
  }

  useEffect(() => {
    dispatch({
      type: 'COOKIE_CONSENT',
      payload: cookies.berneEarthConsent
    })
  }, [])

  useEffect(() => {
    if (
      state.subscriptionStatus.status === 'success' ||
      (state.subscriptionStatus.status === 'error' &&
        state.subscriptionStatus.bypass)
    ) {
      setIsLocked(false)
    } else {
      setIsLocked(true)
    }
  }, [state.subscriptionStatus])

  return (
    // state.isModalOpen && (
    <div>
      <Modal locked={isLocked} open={isLockedOpen}>
        <div className='form-container'>
          <div>
            <h1 className='form--title'>STAY REMIXED</h1>
            <h3 className='form--title'>a remix experience by Berne</h3>
          </div>
          {cookies.berneEarthSubscriptionStatus === 'success' &&
          cookies.berneEarthName ? (
            <WelcomeBackContainer
              name={cookies.berneEarthName}
              onClickDismiss={unlock}
            />
          ) : (
            <MailchimpFormContainer />
          )}
        </div>

        <CookieConsentOverlay onAccept={onCookieConsent} />
      </Modal>
    </div>
    // )
  )
}
export default withCookies(ModalApp)
