import React from 'react'
import Popup from 'reactjs-popup'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share'
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from 'react-share'
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined'
import './Share.css'

const Share = () => {
  return (
    <Popup trigger={<ShareOutlinedIcon fontSize='small' />} modal nested>
      <div className='share-modal'>
        <div className='header'>
          Awesome stuff! Tag us on @soundslikeberne.{' '}
        </div>
        <div className='actions'>
          <span className='actions--item'>
            <FacebookShareButton
              url={window.location.href}
              quote='Checkout Stay Remixed - A remix experience by Berne @soundslikeberne'
              hashtag='#StayRemixed'
            >
              <FacebookIcon />
            </FacebookShareButton>
          </span>
          <span className='actions--item'>
            <WhatsappShareButton
              url={window.location.href}
              title='Checkout Stay Remixed - A remix experience by Berne @soundslikeberne'
            >
              <WhatsappIcon />
            </WhatsappShareButton>
          </span>
          <span className='actions--item'>
            <TwitterShareButton
              title='Checkout Stay Remixed - A remix experience by Berne @soundslikeberne'
              url={window.location.href}
              hashtags={'stayremixed'.split(',')}
            >
              <TwitterIcon />
            </TwitterShareButton>
          </span>
          <span className='actions--item'>
            <LinkedinShareButton
              title='Stay Remixed'
              url={window.location.href}
              source={window.location.href}
              summary='A remix experience by Berne @soundslikeberne'
              hashtags='#StayRemixed'
            >
              <LinkedinIcon />
            </LinkedinShareButton>
          </span>
        </div>
      </div>
    </Popup>
  )
}

export default Share
