import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import './Welcome.css'

const Welcome = props => {
  const [cookies] = useCookies(['berneEarthName'])
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (props.show) {
      setIsVisible(props.show)
    }
  }, [props.show])

  return (
    props.show &&
    isVisible && (
      <div className='welcome'>
        <CloseOutlinedIcon
          className='welcome--close'
          onClick={() => setIsVisible(false)}
        />
        <p className='welcome--title'>
          Got headphones on {cookies.berneEarthName}?
        </p>
        <p className='welcome--text'>
          ‘Stay Remixed’ is an EP collecting remixes of Berne’s debut EP ‘Stay’.
          The tracks were remixed by KNTRL (Malta), WOJTEK (Berlin, Germany),
          Dozier (Los Angeles, USA) and Joanna Åström (Malmö, Sweden).
          <br />
          <br />
          So go ahead, tap a white marker to start! <br /> <br />
          PS: Remember to check out the charities we are supporting in each
          artist card.
        </p>
      </div>
    )
  )
}

export default Welcome
